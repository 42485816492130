/** @jsx jsx */
import { Grid, jsx } from 'theme-ui';

import React from 'react';
import { Flex, Box, Heading, Text, Link } from 'theme-ui';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../plugins/storefront/src/components/Breadcrumbs';

import emsDeliveryTime from '../data/shipping/ems.json';

const ShippingPage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  const {
    pageContext: { hrefLang },
  } = props;

  return (
    <>
      <Helmet
        title={t({
          id: `Shipping.Title`,
          message: `Shipping & delivery`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `Shipping.MetaDescription`,
            message: `We offer express shipping worldwide with EMS international postal express mail service.  All shipments have tracking numbers, and you can track your items 24/7 with delivery confirmation. You will get a shipping confirmation once shipped.`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            productTitle={t({
              id: `Shipping.BreadcrumbsTitle`,
              message: `Shipping & delivery`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 900,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }}>
          <Trans id="Shipping.Heading">Shipping & delivery</Trans>
        </Heading>
        <Text as="p" mt={[2, 3]}>
          <Trans id="Shipping.Description">
            We offer free shipping worldwide with EMS international postal
            express mail service. All shipments have tracking numbers, and you
            can track your items 24/7 with delivery confirmation. You will get a
            shipping confirmation once shipped.
          </Trans>
        </Text>
        {hrefLang === 'ru-BY' ? (
          <Trans id="Shipping.Tracking">
            <Box sx={{ textAlign: 'center' }} mt={[2, 3]}>
              <StaticImage
                src="../images/delivery/ems.jpg"
                alt={t({
                  id: `Shipping.EmsLogoAlt`,
                  message: `Express worldwide shipping`,
                })}
              />
              <Text as="p" mt={[2, 3]}>
                <Link
                  href="https://www.ems.post/en/global-network/tracking"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  Track your order online
                </Link>
              </Text>
            </Box>
          </Trans>
        ) : (
          ''
        )}
        <Trans id="Shipping.DeliveryTiming">
          <Text as="p" mt={[2, 3]}>
            The production of monotape may take 5-14 days, depending on your
            chosen hair color, so please ask a consultant about availability.
          </Text>
          <Text as="p" mt={[2, 3]}>
            If we don't have a monotape ready-made, we will produce it for you
            and ship it the next business day after production. If we have your
            monotape in stock, the order will be shipped next business day if
            placed before 6 PM FET (UTC/GMT+3).
          </Text>
          <Text as="p" mt={[2, 3]}>
            We ship all orders from our manufacture in Eastern Europe - Belarus.
            Please note, international orders are subject to a duty tax that may
            be charged upon delivery. The recipient of the Kisscurls package is
            responsible for this additional charge.
          </Text>
        </Trans>
        <Heading as="h3" mt={[2, 3]}>
          <Trans id="Shipping.EmsDeliveryTimeHeading">
            EMS international delivery time
          </Trans>
        </Heading>
        <Flex
          sx={{ flexWrap: 'wrap', width: '100%', maxWidth: 600, mx: 'auto' }}
          mt={[2, 3]}
        >
          <Flex sx={{ width: '100%' }}>
            <Box sx={{ width: '50%', fontWeight: 500 }}>
              <Trans id="Shipping.CountryLabel">Country</Trans>
            </Box>
            <Box sx={{ width: '25%', fontWeight: 500 }}>
              <Trans id="Shipping.DaysMinLabel">Days, average</Trans>
            </Box>
            <Box sx={{ width: '25%', fontWeight: 500 }}>
              <Trans id="Shipping.DaysMaxLabel">Days, maximum</Trans>
            </Box>
          </Flex>

          {emsDeliveryTime.map((i, k) => (
            <Flex key={k} sx={{ width: '100%' }}>
              <Box sx={{ width: '50%' }}>{i.country}</Box>
              <Box sx={{ width: '25%' }}>{i.delivery_from}</Box>
              <Box sx={{ width: '25%' }}>{i.delivery_to}</Box>
            </Flex>
          ))}
        </Flex>
        <Trans id="Shipping.NotResponsible">
          <Text as="p" mt={[2, 3]}>
            Once your order is shipped, we are unable to honor requests for item
            changes or order cancellations.
          </Text>
          <Text as="p" mt={[2, 3]}>
            Kisscurls is not responsible for packages not received due to an
            incorrect shipping address, for lost or stolen packages.
          </Text>
          <Text as="p" mt={[2, 3]}>
            Please note that shipping carriers may experience delays due to
            unforeseen circumstances, for which Kisscurls is not responsible.
          </Text>
        </Trans>
        <Trans id="Shipping.Contact">
          <Text as="p" mt={[2, 3]}>
            If you need assistance, please{' '}
            <Link as={GatsbyLink} to="/contact">
              contact us
            </Link>{' '}
            Monday-Friday 10am-6pm FET (UTC/GMT+3).
          </Text>
        </Trans>
        <Trans id="Shipping.LostOrder">
          <Heading as="h4" mt={[2, 3]}>
            What should I do in case I didn't receive my order?
          </Heading>
          <Text as="p" mt={[2, 3]}>
            All shipments have tracking numbers, and you can track your items
            24/7 with delivery confirmation. Suppose your order is lost please
            contact us by phone +375 (29) 1833587, we help you handle this
            situation.
          </Text>
        </Trans>
      </Box>
    </>
  );
};

export const ShippingPageQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;

export default ShippingPage;
